/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    hr: "hr",
    div: "div",
    em: "em",
    a: "a",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The ", React.createElement(_components.strong, null, "SWAP gate"), " is a gate in quantum computing that swaps the states of two qubits."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "The diagram below shows how a SWAP gate is represented in quantum circuits."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 45.56962025316456%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABJ0AAASdAHeZh94AAAAnUlEQVQoz51SSQoEIQzs/3/Tkyh6cMclQ8lkcLbGbiEklCapSjzoecYYdOd85h0r2HunlNL0wODZaq3TVozz4Dl+KxhCICEElVKotUY552kopJQia+2Mcc/4z4I7klEErC5JZpncce3MrFdGfyXvMGR5lxgiCXNieesigDvnvvDTpWDDUsrX0L3302KMpLUmY8yMsTzgeHdb8u4/fAD7Gcg5mAbXpQAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"swap-gate-symbol\"\n        title=\"swap-gate-symbol\"\n        src=\"/static/bd19562d24111b3b6b83cfdd9955b5a9/f058b/swap-gate-symbol.png\"\n        srcset=\"/static/bd19562d24111b3b6b83cfdd9955b5a9/c26ae/swap-gate-symbol.png 158w,\n/static/bd19562d24111b3b6b83cfdd9955b5a9/6bdcf/swap-gate-symbol.png 315w,\n/static/bd19562d24111b3b6b83cfdd9955b5a9/f058b/swap-gate-symbol.png 630w,\n/static/bd19562d24111b3b6b83cfdd9955b5a9/e1031/swap-gate-symbol.png 803w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "It can also be represented as"), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 42.405063291139236%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABJ0AAASdAHeZh94AAAAkUlEQVQoz4VR2w4FEQz0/3/KGyHuuhlJpWs5Z5JJaWv0osYYBAK1VmqtzXvv/WUZtxhbJZO11uScm6I558UY43wAP84yBt9LUP58Ax6llJbwDulTsmU+7wRQOQRPeZ8K9xntwGy5Qsztb4UMYwxZa6mUQt57CiEsAhCTPhCfHZcCi6DcIPO05T12bfkGOa9fLT9CmXlnLLzR1AAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"swap-gate-alternate-symbol\"\n        title=\"swap-gate-alternate-symbol\"\n        src=\"/static/e44243c3f1f478400b899708afe2397f/f058b/swap-gate-alternate-symbol.png\"\n        srcset=\"/static/e44243c3f1f478400b899708afe2397f/c26ae/swap-gate-alternate-symbol.png 158w,\n/static/e44243c3f1f478400b899708afe2397f/6bdcf/swap-gate-alternate-symbol.png 315w,\n/static/e44243c3f1f478400b899708afe2397f/f058b/swap-gate-alternate-symbol.png 630w,\n/static/e44243c3f1f478400b899708afe2397f/8b69f/swap-gate-alternate-symbol.png 912w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "The SWAP gate can be decomposed into three CNOT gates, as shown below."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 630px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 44.30379746835443%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABJ0AAASdAHeZh94AAAAzUlEQVQoz51SSQqEQAz0/3dvvsGrf/DqH8QFFNxo1xqqINIzo8ygEFLdSSqVtAEAHMche/r5tYF/2LZNZg3mecY4jorRT9MkPAwDlmV5E2L+JNz3HUVRKHldV92laYooioSrqkJd18JhGCLLMmESXxJejeycOxWykY+p/nZkn9Q/s7spIIFhepvia+Q7dZ8KSWAkPxXaDsuyRNd1SuYDcKd5nqsgjmMkSaI83jGXOUZ8uUMG+cosolFd3/eKNU2Dtm2F2ZRklvf3yE/+wxdIZ8FnttcypAAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"making-a-swap-gate-from-cnot-gates\"\n        title=\"making-a-swap-gate-from-cnot-gates\"\n        src=\"/static/51a7a86b53b9abf0d6ba8827c2402108/f058b/making-a-swap-gate-from-cnot-gates.png\"\n        srcset=\"/static/51a7a86b53b9abf0d6ba8827c2402108/c26ae/making-a-swap-gate-from-cnot-gates.png 158w,\n/static/51a7a86b53b9abf0d6ba8827c2402108/6bdcf/making-a-swap-gate-from-cnot-gates.png 315w,\n/static/51a7a86b53b9abf0d6ba8827c2402108/f058b/making-a-swap-gate-from-cnot-gates.png 630w,\n/static/51a7a86b53b9abf0d6ba8827c2402108/6029f/making-a-swap-gate-from-cnot-gates.png 906w\"\n        sizes=\"(max-width: 630px) 100vw, 630px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Thus, the SWAP gate can be thought of as a convenient shorthand for this sequence of CNOT gates."), "\n", React.createElement(_components.p, null, "Mathematically, the SWAP gate's action on a quantum state is"), "\n", React.createElement(_components.p, null, "$$\n\\text{SWAP} (|\\phi_{1}\\rangle \\otimes |\\phi_{2}\\rangle) = |\\phi_{2}\\rangle \\otimes |\\phi_{1}\\rangle.\n$$"), "\n", React.createElement(_components.p, null, "The SWAP gate can be represented in matrix form as"), "\n", React.createElement(_components.p, null, "$$\n\\text{SWAP} \\equiv \\left[ \\begin{matrix}1 & 0 & 0 & 0 \\\\ 0 & 0 & 1 & 0 \\\\ 0 & 1 & 0 & 0 \\\\ 0 & 0 & 0 & 1 \\end{matrix} \\right].\n$$"), "\n", React.createElement(_components.p, null, "The SWAP gate is extremely useful in hardware settings; if two qubits are not physically connected, we can simply ", React.createElement(_components.em, null, "swap"), " one of those qubits with another that ", React.createElement(_components.em, null, "is"), " physically connected to the other qubit. The SWAP gate may also appear as a necessary part in building the quantum Fourier transform or in other routines such as the SWAP test."), "\n", React.createElement(_components.p, null, "The ", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/code/api/pennylane.SWAP.html"
  }, "SWAP gate in PennyLane"), " is available as ", React.createElement(_components.code, null, "qml.SWAP"), ". This gate is called ", React.createElement(_components.a, {
    href: "https://docs.quantum.ibm.com/api/qiskit/qiskit.circuit.library.SwapGate"
  }, React.createElement(_components.code, null, "SwapGate"), " in Qiskit"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
